import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

function NotFoundPage() {
	return (
		<Layout>
			<SEO title="404: Not found" />
			<div>
				<h2 className="text-2xl font-medium inline-block my-8">Diese Seite wurde nicht gefunden ...</h2>
				<h2 className="text-xl text-primary p-5">
					<Link to="/"> Gehe zurück zum Start</Link>
				</h2>
			</div>
		</Layout>
	);
}

export default NotFoundPage;
